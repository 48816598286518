import { PowerCableIcon, ElectricPoleIcon, PlantLeafIcon } from '@assets/icons'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Box, Divider, styled, Typography, useTheme } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import Show from '@src/ui/wrappers/Show/Show'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContentItem from './ContentItem'
import { hasValue } from './DtpInvoiceDetailsUtills'
import HighlightedContent from './HighlightedContent'

interface IDtpInvoiceDetailsProps {
  gpnr?: string
  billingPeriodFromDate?: string
  billingPeriodToDate?: string
  invoiceID?: string
  invoiceStatusID?: string
  contractID?: string
  currency?: string
  amountEnergyDelivery?: string
  amountEdHt?: string
  consumptionHT?: string
  amountReducHt?: string
  amountEdNt?: string
  consumptionNT?: string
  amountReducNt?: string
  consumptionET?: string
  consumption?: string
  amountNetworkAccess?: string
  amountNaHt?: string
  amountNaNt?: string
  amountPower?: string
  consumptionPower?: string
  amountReactive?: string | null
  amountReHt?: string | null
  consumptionReHt?: string | null
  amountReNt?: string | null
  consumptionReNt?: string | null
  amountFee?: string
  amountFeeB?: string
  amountFeeKa?: string
  amountFeeMwst?: string
  amountPostFee?: string
  amountDunningFee?: string
  amountServices?: string
  amountWorkCompensation?: string
  amountTotal?: string
  amountCredit?: string | null
}

const hoverAndActiveStyles = {
  background: '#365F10',
  color: '#fff',
  borderRadius: '50%',
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
  border: 'none',
  '&::before': {
    display: 'none',
  },
})

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ width: 24, padding: 0.5 }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  padding: 0,
  '&:hover': {
    '& .MuiAccordionSummary-expandIconWrapper': {
      ...hoverAndActiveStyles,
    },
    '& .MuiAccordionSummary-content p': {
      fontWeight: 600,
    },
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    ...hoverAndActiveStyles,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: theme.spacing(1),
    '&.Mui-expanded p': {
      fontWeight: 600,
    },
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  paddingLeft: 0,
  paddingRight: 0,
}))

const DtpInvoiceDetails = (props: IDtpInvoiceDetailsProps) => {
  const {
    amountEnergyDelivery,
    amountEdHt,
    amountReducHt,
    consumptionHT,
    consumptionNT,
    amountEdNt,
    amountReducNt,
    amountNetworkAccess,
    consumptionReHt,
    consumptionReNt,
    amountReHt,
    amountReNt,
    amountFee,
    amountFeeB,
    amountFeeKa,
    amountCredit,
    amountFeeMwst,
    amountPostFee,
    amountDunningFee,
    amountNaHt,
    amountNaNt,
    amountTotal,
    amountWorkCompensation,
  } = props

  const theme = useTheme()
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded && panel)
  }

  const isCreditNote = amountTotal && amountTotal?.includes('-')

  return (
    <Box
      sx={{
        '& .MuiDivider-root + .MuiDivider-root': {
          display: 'none',
        },
      }}
    >
      <Typography variant="heading4" sx={{ mb: 4 }}>
        {t('invoices.invoice_cost_composition')}
      </Typography>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <ContentItem label={t('invoices.invoice_energy_consumption')} value={amountEnergyDelivery} />
        </AccordionSummary>
        <AccordionDetails>
          <HighlightedContent
            icon={<PowerCableIcon sx={{ color: 'transparent', mx: 2 }} />}
            label={t('invoices.invoice_explanation_energy')}
          />
          <Show when={hasValue(amountEdHt)}>
            <ContentItem label={`${t('invoices.ic_highrate')}: ${consumptionHT} kWh`} value={amountEdHt} />
          </Show>
          <Show when={hasValue(amountReducHt)}>
            <ContentItem label={t('invoices.ic_high_rate_reductions')} value={amountReducHt} />
          </Show>
          <Show when={hasValue(amountEdNt)}>
            <ContentItem label={`${t('invoices.ic_lowrate')}: ${consumptionNT} kWh`} value={amountEdNt} />
          </Show>
          <Show when={hasValue(amountReducNt)}>
            <ContentItem label={t('invoices.ic_low_rate_reductions')} value={amountReducNt} />
          </Show>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <ContentItem label={t('invoices.invoice_grid_usage')} value={amountNetworkAccess} />
        </AccordionSummary>
        <AccordionDetails>
          <HighlightedContent
            icon={<ElectricPoleIcon sx={{ color: 'transparent', mx: 2 }} />}
            label={t('invoices.invoice_explanation_grid')}
          />
          <Show when={hasValue(amountNaHt)}>
            <ContentItem label={`${t('invoices.ic_high_rate_grid_usage')}: ${consumptionHT} kWh`} value={amountNaHt} />
          </Show>
          <Show when={hasValue(amountNaNt)}>
            <ContentItem label={`${t('invoices.ic_low_rate_grid_usage')}: ${consumptionHT} kWh`} value={amountNaNt} />
          </Show>
          <Show when={hasValue(amountReHt)}>
            <ContentItem
              label={`${t('invoices.ic_high_rate_reactive_power')}: ${consumptionReHt} kvar`}
              value={amountReHt}
            />
          </Show>
          <Show when={hasValue(amountReNt)}>
            <ContentItem
              label={`${t('invoices.ic_low_rate_reactive_power')}: ${consumptionReNt} kvar`}
              value={amountReNt}
            />
          </Show>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <ContentItem label={t('invoices.invoice_public_charges')} value={amountFee} />
        </AccordionSummary>
        <AccordionDetails>
          <HighlightedContent
            icon={<PlantLeafIcon sx={{ color: 'transparent', mx: 2 }} />}
            label={t('invoices.invoice_explanation_community_taxes')}
          />
          <Show when={hasValue(amountFeeB)}>
            <ContentItem label={t('invoices.invoice_federal_taxes')} value={amountFeeB} />
          </Show>
          <Show when={hasValue(amountFeeKa)}>
            <ContentItem label={t('invoices.invoice_concession_fee')} value={amountFeeKa} />
          </Show>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mb: 2.5, mt: 1.5, bgcolor: theme.palette.divider }} />

      <Show when={hasValue(amountCredit)}>
        <ContentItem
          label={t('invoices.ic_credit_balance')}
          value={amountCredit}
          valueSx={{ color: theme.palette.background.primaryActive }}
        />
      </Show>
      <Show when={hasValue(amountFeeMwst)}>
        <ContentItem label={t('invoices.invoice_vat')} value={amountFeeMwst} />
      </Show>
      <Show when={hasValue(amountPostFee)}>
        <ContentItem label={t('invoices.invoice_postal_deposit_fees')} value={amountPostFee} />
      </Show>
      <Show when={hasValue(amountDunningFee)}>
        <ContentItem label={t('invoices.ic_reminder_fees')} value={amountDunningFee} />
      </Show>
      <Show when={hasValue(amountWorkCompensation)}>
        <ContentItem label={t('invoices.ic_work_compensation')} value={amountWorkCompensation} />
      </Show>

      <Divider sx={{ my: 2.5, bgcolor: theme.palette.text.secondary }} />
      <ContentItem
        label={t('invoices.invoice_amount')}
        value={amountTotal ? `CHF ${amountTotal}` : ''}
        labelSx={{ fontWeight: '700', fontSize: 22 }}
        valueSx={{
          fontWeight: '700',
          fontSize: 24,
          color: isCreditNote ? theme.palette.primary.main : theme.palette.text.primary,
        }}
      />
    </Box>
  )
}

export default DtpInvoiceDetails
