import { InvoiceIcon, ContractConsumptionIcon, ArrowRightIcon } from '@assets/icons'
import { cardPalette } from '@assets/themes/palette'
import useLocationsData from '@hooks/useLocations/useLocationsData'
import { IContract } from '@interfaces/contracts'
import { Button, Box, Stack, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

interface IContractRedirect extends IContract {
  locationIdRedirect?: string | number
}
export interface IDtpContractCardProps {
  address: string
  contract: IContractRedirect
}

interface IContractInfoProps {
  label: string
  value?: string
  slot?: React.ReactNode
}

const getContractId = (contract: IContractRedirect) =>
  contract.locationIdRedirect && dayjs(contract.supplyEndDate).isBefore(dayjs())
    ? contract.contractNumber
    : contract.locationIdRedirect || contract.contractNumber

const CustomText = ({ children, subtext = false }: { children: React.ReactNode; subtext?: boolean }) => {
  return (
    <Typography sx={{ margin: 0, wordBreak: 'break-all' }} variant={subtext ? 'bodyRegular' : 'bodyRegularEmphasized'}>
      {children}
    </Typography>
  )
}

const ContractInfo = ({ label, value, slot }: IContractInfoProps) => (
  <Stack rowGap={0.5} alignItems="flex-start">
    <Show when={!!label}>
      <CustomText>{label}:</CustomText>
    </Show>
    <Show when={!!value}>
      <CustomText subtext>{value}</CustomText>
    </Show>
    <Show when={!!slot}>{slot}</Show>
  </Stack>
)

const DtpContractCard = ({ address, contract }: IDtpContractCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setShowExpiredContracts } = useLocationsData()

  const contractExpired: boolean = Boolean(
    contract.supplyEndDate && contract.supplyEndDate !== '' && dayjs().isAfter(contract.supplyEndDate)
  )

  return (
    <Box
      maxWidth={{ xs: '100%', md: 360 }}
      width={'100%'}
      borderRadius={6}
      border={`1px solid ${cardPalette.border}`}
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Stack rowGap={2} sx={{ px: 3, pb: 2, pt: 3 }}>
        <Typography sx={{ m: '0 0 8px 0' }} variant="leadEmphasized">
          {contract.contractDescription ?? t('contracts.error_message_no_data_available')}
        </Typography>
        <ContractInfo label={t('contracts.contract_address')} value={address} />
        <ContractInfo
          label={t('contracts.meter_Id')}
          slot={
            <Typography sx={{ margin: 0 }} variant="bodyRegular">
              {contract.meterId ? (
                <>
                  {contract.meterId.slice(0, -7)}
                  <br />
                  <strong>{contract.meterId.slice(-7)}</strong>
                </>
              ) : (
                <>{t('contracts.error_message_no_data_available')}</>
              )}
            </Typography>
          }
        />
        <ContractInfo
          label={t('contracts.energy_product')}
          slot={
            <Button variant="text" sx={{ px: { xs: 2 }, py: { xs: 0.5 }, ml: -2 }} endIcon={<ArrowRightIcon />}>
              {contract.product ?? t('contracts.error_message_no_data_available')}
            </Button>
          }
        />
        <Show when={!contractExpired}>
          <ContractInfo
            label={t('contracts.contract_start_date')}
            value={
              contract.supplyStartDate
                ? dayjs(contract.supplyStartDate).format('DD.MM.YYYY')
                : t('contracts.error_message_no_data_available')
            }
          />
        </Show>
        <Show when={contractExpired}>
          <ContractInfo
            label={t('contracts.contract_end_date')}
            value={
              contract.supplyEndDate
                ? dayjs(contract.supplyEndDate).format('DD.MM.YYYY')
                : t('contracts.error_message_no_data_available')
            }
          />
        </Show>
        <ContractInfo
          label={t('contracts.contract_number')}
          value={contract.contractNumber ?? t('contracts.error_message_no_data_available')}
        />
      </Stack>
      <Stack
        sx={{
          px: 1,
          pt: 1,
          pb: { xs: 1 },
          bgcolor: cardPalette.stackBgColor,
          alignItems: 'flex-start',
          marginTop: 'auto',
        }}
      >
        <Show when={false}>
          <Button
            sx={{ justifyContent: 'start', pl: { xs: 2 }, py: { xs: 1 } }}
            variant="text"
            startIcon={<InvoiceIcon sx={{ height: '20px' }} />}
          >
            {t('contracts.invoices')}
          </Button>
        </Show>
        <Button
          sx={{ justifyContent: 'start', pl: { xs: 2 }, py: { xs: 1 } }}
          variant="text"
          startIcon={<ContractConsumptionIcon sx={{ height: '20px' }} />}
          onClick={() => {
            if (dayjs(contract.supplyEndDate).isBefore(dayjs())) {
              setShowExpiredContracts(true)
            }
            navigate(t('navigation_url.energy_consumption'), {
              state: {
                tab: 0,
                contractId: getContractId(contract),
              },
            })
          }}
        >
          {t('contracts.energy_consumption')}
        </Button>
      </Stack>
    </Box>
  )
}

export default DtpContractCard
