import DtpHeroCard from '@components/DtpHeroCard/DtpHeroCard'
import DtpInvoiceCardGroup from '@components/DtpInvoiceCardGroup/DtpInvoiceCardGroup'
import DtpNoOpenInvoices from '@components/DtpNoOpenInvoices/DtpNoOpenInvoices'
import DtpInvoiceCardGroupSkeleton from '@components/Skeletons/DtpInvoiceCardGroupSkeleton'
import { useInvoices } from '@hooks/useInvoices/useInvoices'
import { Container } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'

const Home = () => {
  const { invoices, isLoading } = useInvoices({ status: 'OPEN' })
  const openInvoices = Object.values(invoices).flat()

  return (
    <>
      <DtpHeroCard />
      <Container>
        <Show when={!isLoading}>
          <DtpInvoiceCardGroup invoices={openInvoices} />
        </Show>
        <Show when={isLoading}>
          <DtpInvoiceCardGroupSkeleton />
        </Show>
        <Show when={!openInvoices.length && !isLoading}>
          <DtpNoOpenInvoices />
        </Show>
      </Container>
    </>
  )
}

export default Home
