import { ISelectItem } from '@components/DtpSelect/DtpSelect'
import { deliveryType } from '@enums/deliveryType'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import { IContractLocation } from '@interfaces/contracts'

import { calculateStartEndDateForLocation } from './dateFunctions'

export const prepackLocationData = (locations: IContractLocation[], gpnr: string): ISelectItem<IAddOn>[] => {
  if (!locations.length) return []

  return locations.flatMap((location) => {
    const { contracts, id } = location
    const { startDate, endDate } = calculateStartEndDateForLocation(locations, id)
    const locationItem = {
      value: id,
      label: getLocationAddress(location),
      addOn: {
        gpnr: gpnr,
        supplyStartDate: startDate,
        supplyEndDate: endDate,
        meterId: undefined,
        locationId: location.id,
        contractId: undefined,
        contractType: undefined,
        deliveryType: undefined,
        isFeedInLocation: contracts.length === 1 ? contracts[0].deliveryType === deliveryType.FEEDIN : false,
        hasFeedInContractsInLocation: contracts.some((el) => el.deliveryType === deliveryType.FEEDIN),
      },
    }
    if (contracts.length) {
      const tempMetersArray = contracts.map((contract) => {
        return {
          value: contract.contractNumber,
          label: contract.contractDescription,
          addOn: {
            gpnr: gpnr,
            supplyStartDate: contract.supplyStartDate,
            supplyEndDate: contract.supplyEndDate,
            meterId: contract.meterId,
            locationId: undefined,
            contractId: contract.contractNumber,
            hasMoreThanOneContract: contracts.length > 1,
            isFeedInContract: contract.deliveryType === deliveryType.FEEDIN,
            parentLocation: getLocationAddress(location),
            locationOrContractId: contracts.length === 1 ? id : contract.contractNumber,
            contractType: contract.contractType,
            deliveryType: contract.deliveryType,
          },
        }
      })

      return [locationItem, ...tempMetersArray]
    } else {
      return locationItem
    }
  })
}

export const getLocationAddress = ({ streetAddress, postalCode, city }: Omit<IContractLocation, 'contracts' | 'id'>) =>
  `${streetAddress}, ${postalCode} ${city}`

export const filterLocations = (
  locations: ISelectItem<IAddOn>[],
  searchTerm: string,
  value?: string
): ISelectItem<IAddOn>[] => {
  return (
    locations.filter(
      (location) =>
        location.label.toLowerCase().includes(searchTerm.toLowerCase()) || (value && location.value.includes(value))
    ) || []
  )
}

export const formatContracts = (locations: IContractLocation[] | undefined) =>
  (locations || []).flatMap((location) => {
    return location.contracts.map((contract) => {
      return {
        address: getLocationAddress(location),
        contract: {
          ...contract,
          locationIdRedirect: location.contracts.length === 1 ? location.id : undefined,
        },
      }
    })
  })
