import DtpPhoneInput from '@components/DtpPhoneInput/DtpPhoneInput'
import DtpTextField from '@components/DtpTextField/DtpTextField'
import { Box, Button, Stack, CircularProgress, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { FormikErrors } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IPersonFormProps {
  isUpdatingAccountDetails: boolean
  readOnlyName?: string
  disabled: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  values: {
    lastName1: string | null
    firstName2: string | null
    lastName2: string | null
    phoneNumber: string | null
    email: string | null
  }
  errors: FormikErrors<{
    lastName1: string
    firstName2: string
    lastName2: string
    phoneNumber: string
    email: string
  }>
  setFieldValue: (field: string, value: React.ChangeEvent) => void
}

const PersonForm = ({
  isUpdatingAccountDetails,
  onChange,
  values,
  onSubmit,
  errors,
  setFieldValue,
  readOnlyName,
  disabled,
}: IPersonFormProps) => {
  const { t } = useTranslation()
  return (
    <form onSubmit={onSubmit}>
      <Stack gap={2} mt={2}>
        <DtpTextField
          defaultValue={readOnlyName}
          label={`${t('personal_data.personal_data_first_name')} (${t('personal_data.not_editable')})`}
          name="firstName1"
          fullWidth
          disabled
          required
        />
        <DtpTextField
          defaultValue={values.lastName1}
          label={requiredLabel(t('personal_data.personal_data_last_name'))}
          name="lastName1"
          onChange={onChange}
          error={Boolean(errors.lastName1)}
          helperText={errors.lastName1}
          fullWidth
        />
        <DtpTextField
          defaultValue={values.firstName2}
          label={`${t('personal_data.personal_data_first_name')} (${t('personal_data.personal_data_additional_person')})`}
          name="firstName2"
          onChange={onChange}
          placeholder={t('personal_data.hint_first_name_additional_person')}
          error={Boolean(errors.firstName2)}
          helperText={errors.firstName2}
          fullWidth
        />
        <DtpTextField
          defaultValue={values.lastName2}
          label={`${t('personal_data.personal_data_last_name')} (${t('personal_data.personal_data_additional_person')})`}
          name="lastName2"
          onChange={onChange}
          placeholder={t('personal_data.hint_last_name_additional_person')}
          error={Boolean(errors.lastName2)}
          helperText={errors.lastName2}
          fullWidth
        />
        <DtpPhoneInput
          defaultCountry="CH"
          name="phoneNumber"
          value={values.phoneNumber ?? ''}
          label={t('personal_data.personal_data_telephone')}
          onChange={(value: string) => setFieldValue('phoneNumber', value as unknown as React.ChangeEvent<Element>)}
          id="phoneNumber"
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber}
          fullWidth
          onlyCountries={['CH']}
        />
        <DtpTextField
          type="email"
          defaultValue={values.email}
          label={t('personal_data.personal_data_email')}
          name="email"
          onChange={onChange}
          error={Boolean(errors.email)}
          helperText={errors.email}
          fullWidth
          required
        />
        <Box>
          <Button type="submit" size="small" disabled={disabled || isUpdatingAccountDetails}>
            <Show when={isUpdatingAccountDetails}>
              <CircularProgress size={30} color="inherit" />
            </Show>
            <Show when={!isUpdatingAccountDetails}>{t('save')}</Show>
          </Button>
        </Box>
      </Stack>
    </form>
  )
}

export default PersonForm

const requiredLabel = (label: string) => (
  <>
    {label}
    <Typography component="span" color="error">
      &nbsp; *
    </Typography>
  </>
)
