import i18next from 'i18next'
import * as yup from 'yup'

const { t } = i18next

export const personDetailsSchema = yup
  .object({
    lastName1: yup
      .string()
      .matches(/^[a-zA-Z-]*$/, t('personal_data.validation_error_input_name'))
      .max(30, t('personal_data.validation_error_firstname_max_length'))
      .required(t('personal_data.validation_error_manditory_field')),
    firstName2: yup
      .string()
      .matches(/^[a-zA-Z-]*$/, t('personal_data.validation_error_input_name'))
      .max(30, t('personal_data.validation_error_firstname_max_length'))
      .nullable(),
    lastName2: yup
      .string()
      .matches(/^[a-zA-Z-]*$/, t('personal_data.validation_error_input_name'))
      .max(30, t('personal_data.validation_error_lastname_max_length'))
      .nullable(),
    phoneNumber: yup
      .string()
      .matches(/^[0-9+\s]*$/, t('personal_data.validation_error_permitted_characters'))
      .max(20, t('personal_data.validation_error_phone_number_length'))
      .nullable(),
    email: yup
      .string()
      .email(t('personal_data.validation_error_email_format'))
      .max(80, t('personal_data.validation_error_email_length'))
      .required(t('personal_data.validation_error_email_required')),
  })
  .test('firstName2-lastName2-validation', 'Both fields must be filled or both should be empty', function (values) {
    const { firstName2, lastName2 } = values

    if ((!firstName2 && !lastName2) || (firstName2 && lastName2)) {
      return true
    }

    return this.createError({
      path: firstName2 ? 'lastName2' : 'firstName2',
      message: t('personal_data.validation_error_two_fields_dependency'),
    })
  })
