import { Skeleton, Box, Stack } from '@mui/material'

const DtpInvoiceDetailsSkeleton = () => {
  return (
    <Box>
      <Skeleton width="50%" height={30} sx={{ mb: 2 }} />
      <Stack direction="row" justifyContent="space-between" py={0.2}>
        <Skeleton width={140} height={35} />
        <Skeleton width={30} height={35} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" py={0.2}>
        <Skeleton width={180} height={35} />
        <Skeleton width={30} height={35} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" py={0.2}>
        <Skeleton width={160} height={35} />
        <Skeleton width={30} height={35} />
      </Stack>
      <Skeleton height={3} sx={{ margin: '20px 0' }} />
      <Stack direction="row" justifyContent="space-between" py={0.2}>
        <Skeleton width={160} height={35} />
        <Skeleton width={30} height={35} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" py={0.2}>
        <Skeleton width={160} height={35} />
        <Skeleton width={30} height={35} />
      </Stack>
      <Skeleton height={3} sx={{ margin: '20px 0' }} />
      <Stack direction="row" justifyContent="space-between" py={0.2}>
        <Skeleton width={160} height={35} />
        <Skeleton width={30} height={35} />
      </Stack>
    </Box>
  )
}

export default DtpInvoiceDetailsSkeleton
