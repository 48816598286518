import { withErrorLogging } from '@helpers/hof'
import { IContractsApiResponse } from '@interfaces/contracts'
import { IInvoiceProps, IInvoicesApiResponse } from '@interfaces/invoices'
import { ILastAvailableDayParams } from '@interfaces/lastAvailableDay'
import {
  IAggregationMeasurementsProps,
  IDownloadAggregationMeasurementsProps,
  IMeasurementResponse,
  IComparisonProps,
} from '@interfaces/measurements'

import { api, apiAccount, apiInvoice } from './index'

export const getLastAvailableDay = withErrorLogging(
  async (params: ILastAvailableDayParams): Promise<IMeasurementResponse> => {
    const { data } = await api.post(
      `/v1/electricityconsumption/lastavailableday/${params.locationId ? 'location' : 'meter'}`,
      { ...params }
    )
    return data
  }
)

export const getAggregationMeasurements = withErrorLogging(
  async (params: IAggregationMeasurementsProps): Promise<IMeasurementResponse> => {
    const { data } = await api.post(
      `/v1/electricityconsumption/measurements/${params.locationId ? 'location' : 'meter'}`,
      { ...params }
    )
    return data
  }
)
export const getDownloadAggregationMesurements = async (
  params: IDownloadAggregationMeasurementsProps
): Promise<File> => {
  const { data } = await api.post(`v1/file/${params.locationId ? 'location' : 'meter'}`, { ...params })
  return data
}

export const getComparisonData = async (params: IComparisonProps) => {
  const { data } = await api.post(`/v1/electricityconsumption/comparison/${params.locationId ? 'location' : 'meter'}`, {
    ...params,
  })
  return data
}

export const getAccounts = async () => {
  const { data } = await apiAccount.get('/v1/account')
  return data
}

export const getAccountDetails = async (gpnr: string) => {
  const { data } = await apiAccount.get(`/v1/account/${gpnr}/details`)
  return data
}

export const getContracts = async (gpnr: string): Promise<IContractsApiResponse> => {
  const { data } = await apiAccount.get('/v1/account/contracts', { params: { gpnr } })
  return data
}

export const getInvoices = async (params: IInvoiceProps): Promise<IInvoicesApiResponse> => {
  const { data } = await apiInvoice.post('/v1/invoice/overview', { ...params })
  return data
}

export const getInvoiceContent = async ({ invoiceId, gpnr }: { invoiceId: string; gpnr: string }) => {
  const { data } = await apiInvoice.get(`/v1/invoice/${invoiceId}/content`, { params: { gpnr } })
  return data
}
