import { DownloadIcon, DocumentIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import { getFormattedBillingPeriod } from '@helpers/invoices'
import { IInvoice } from '@interfaces/invoices'
import { Box, Typography, Stack } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { CustomTableCell, CustomDownloadButton } from './DtpPaidInvoiceAccordionUtills'

interface IInvoiceRowDesktopProps {
  row: IInvoice
  multipleContracts: boolean
  onDownloadPdf: (invoiceId: string) => void
}

const InvoiceRowDesktop = ({ row, multipleContracts, onDownloadPdf }: IInvoiceRowDesktopProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isCreditNote = row.amountTotal && row?.amountTotal.includes('-')
  const billingPeriod = getFormattedBillingPeriod(row.billingPeriodFromDate, row.billingPeriodToDate)
  return (
    <>
      <CustomTableCell>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          columnGap="24px"
          onClick={() => navigate(`${t('navigation_url.invoices')}/${row.invoiceId}`)}
        >
          <DocumentIcon sx={{ width: 48, height: 48, fill: '#EDEEED' }} />
          <Box>
            <Typography variant="bodySmallEmphasized" sx={{ margin: 0 }}>
              {`${t(isCreditNote ? 'invoices.invoice_payout' : 'invoices.invoice')} ${billingPeriod}`}
            </Typography>
            <Show when={multipleContracts}>
              <Typography
                variant="bodySmall"
                sx={{ margin: 0, fontFamily: 'GothamBook', fontWeight: 400, color: theme.palette.text.secondary }}
              >
                {row.contractId}
              </Typography>
            </Show>
          </Box>
        </Stack>
      </CustomTableCell>
      <CustomTableCell>
        <Typography
          variant="bodySmall"
          sx={{
            fontFamily: 'GothamBook',
            fontWeight: 400,
            color: theme.palette.text.secondary,
          }}
        >
          {row.invoiceId}
        </Typography>
      </CustomTableCell>
      <CustomTableCell>
        {/* check for empty state */}
        <Show when={!row.amountTotal}>
          <Typography variant="bodySmallEmphasized" color={isCreditNote ? theme.palette.primary.main : 'auto'}>
            -
          </Typography>
        </Show>
        <Show when={!!row.amountTotal}>
          <Typography variant="bodySmallEmphasized" color={isCreditNote ? theme.palette.primary.main : 'auto'}>
            {`${isCreditNote ? `+${row.amountTotal.slice(1)}` : row.amountTotal} ${row.currency}`}
          </Typography>
        </Show>
      </CustomTableCell>
      <CustomTableCell>
        <CustomDownloadButton
          disableRipple
          size="medium"
          variant="text"
          onClick={() => onDownloadPdf(row.invoiceId)}
          startIcon={<DownloadIcon />}
        >
          PDF
        </CustomDownloadButton>
      </CustomTableCell>
    </>
  )
}

export default InvoiceRowDesktop
