import { DocumentIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import { getFormattedBillingPeriod } from '@helpers/invoices'
import { IInvoice } from '@interfaces/invoices'
import { Stack, Box, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const InvoiceRowMobile = ({ row, multipleContracts }: { row: IInvoice; multipleContracts: boolean }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isCreditNote = row.amountTotal && row?.amountTotal.includes('-')
  const billingPeriod = getFormattedBillingPeriod(row.billingPeriodFromDate, row.billingPeriodToDate)
  return (
    <Stack
      direction="row"
      justifyContent="start"
      alignItems="start"
      columnGap="16px"
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(`${t('navigation_url.invoices')}/${row.invoiceId}`)}
    >
      <DocumentIcon sx={{ width: 48, height: 48, fill: '#EDEEED' }} />
      <Box>
        <Typography variant="bodyRegularEmphasized" sx={{ margin: 0 }}>
          {`${t(isCreditNote ? 'invoices.invoice_payout' : 'invoices.invoice')} ${billingPeriod}`}
        </Typography>
        <Typography
          variant="caption"
          sx={{ margin: 0, fontWeight: 400, fontSize: { xs: '14px' }, color: theme.palette.text.secondary }}
        >
          {`${t(isCreditNote ? 'invoices.invoice_credit_note_number' : 'invoices.invoice_nr')} ${row.invoiceId}`}
        </Typography>
        <Show when={multipleContracts}>
          <Typography
            variant="caption"
            sx={{ margin: 0, fontWeight: 400, fontSize: { xs: '14px' }, color: theme.palette.text.secondary }}
          >
            {row.contractId}
          </Typography>
        </Show>
        <Stack direction="row">
          <Typography
            variant="bodySmallEmphasized"
            sx={{ margin: 0, fontWeight: 400, fontSize: { xs: '14px' }, color: theme.palette.text.secondary }}
          >
            {t('invoices.invoice_amount')}
          </Typography>
          <Typography
            variant="bodySmallEmphasized"
            sx={{ margin: 0, ml: 1, color: isCreditNote ? theme.palette.primary.main : theme.palette.text.secondary }}
          >
            {isCreditNote ? `+${row.amountTotal.slice(1)}` : row.amountTotal} {row.currency}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  )
}

export default InvoiceRowMobile
