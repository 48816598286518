import { withErrorLogging } from '@helpers/hof'
import { IAccountDetails } from '@interfaces/accounts'

import { apiUser, apiInvoice, apiAccount } from './index'

export const setMainAccount = withErrorLogging(async ({ gpnr }: { gpnr: string }) => {
  const { data } = await apiUser.post('/v1/user', {
    configuration: {
      mainAccount: gpnr,
    },
  })
  return data
})

export const downloadInvoice = async ({ gpnr, invoiceId }: { gpnr: string; invoiceId: string }) => {
  const { data } = await apiInvoice.post('/v1/invoice/pdf', { gpnr, invoiceId }, { responseType: 'blob' })
  return data
}

export const updateAccountFields = async ({
  gpnr,
  updatedDetails,
}: {
  gpnr: string
  updatedDetails: Partial<IAccountDetails>
}) => {
  const { data } = await apiAccount.put(`/v1/account/${gpnr}/details`, updatedDetails)
  return data
}
