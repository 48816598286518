import DtpErrorModal from '@components/DtpErrorModal/DtpErrorModal'
import { IPersonDetails } from '@hooks/useAccountDetails/useAccountDetails'
import { IAccountDetails } from '@interfaces/accounts'
import CancelIcon from '@mui/icons-material/Cancel'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import { Typography, Box, Stack, IconButton, Divider, Button } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { personDetailsSchema } from '@src/validations/personDetailsSchema'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ContactInfoField } from './ContactInfoField'
import PersonForm from './PersonForm'

export interface IDtpPersonalDataFieldsProps extends IAccountDetails {
  updateAccountDetails: (
    data: IPersonDetails,
    options: {
      onSuccess: (data: IAccountDetails) => void
      onError: (error: Error) => void
    }
  ) => void
  refetchAccountDetails: () => void
  refetchAccounts: () => void
  isUpdatingAccountDetails: boolean
}
const DtpPersonalDataFields = ({
  accountType,
  customerName,
  companyAddition,
  firstName1,
  lastName1,
  firstName2,
  lastName2,
  phoneNumber,
  email,
  address,
  postalCode,
  city,
  invoiceEmail,
  updateAccountDetails,
  refetchAccounts,
  refetchAccountDetails,
  isUpdatingAccountDetails,
}: IDtpPersonalDataFieldsProps) => {
  const { t } = useTranslation()

  const [showContactForm, setShowContactForm] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)

  const handleOnUpdateSuccess = () => {
    refetchAccounts()
    refetchAccountDetails()
    setShowContactForm(false)
  }

  const handleOpenModal = () => setErrorModalOpen(true)

  const initialValues = {
    invoiceEmail: invoiceEmail,
    lastName1: lastName1,
    firstName2: firstName2,
    lastName2: lastName2,
    phoneNumber: phoneNumber,
    email: email,
  }

  const { handleChange, handleSubmit, values, setFieldValue, errors, resetForm, dirty } = useFormik({
    initialValues: initialValues,
    validationSchema: personDetailsSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (accountDetails) => {
      updateAccountDetails(
        {
          ...accountDetails,
          phoneNumber: accountDetails.phoneNumber === '' ? null : accountDetails.phoneNumber,
        },
        {
          onSuccess: handleOnUpdateSuccess,
          onError: handleOpenModal,
        }
      )
    },
  })
  const handleShowForm = () => setShowContactForm((prev) => !prev)

  const handleCancel = () => {
    setShowContactForm(false)
    resetForm()
  }

  const isSaveDisabled = !dirty || Object.keys(errors).length > 0

  return (
    <>
      <Box>
        <Typography variant="heading3" sx={{ mt: { xs: 1.5, md: 6 }, mb: 4 }}>
          <Show when={accountType === 'Person'}>{t('personal_data.personal_data')}</Show>
          <Show when={accountType === 'Organisation'}>{t('personal_data.personal_data_company_name')}</Show>
        </Typography>
        <Box maxWidth="679px">
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography sx={{ my: 0 }} variant="leadEmphasized">
                {t('personal_data.personal_data_contact')}
              </Typography>
              <Show when={!showContactForm}>
                <IconButton sx={{ height: '42px' }} onClick={handleShowForm}>
                  <EditOutlinedIcon />
                </IconButton>
              </Show>
              <Show when={showContactForm}>
                <Button
                  variant="text"
                  size="small"
                  startIcon={<CancelIcon />}
                  color="inherit"
                  sx={{ color: '#4F544F', justifyContent: 'flex-end' }}
                  onClick={handleCancel}
                >
                  {t('cancel')}
                </Button>
              </Show>
            </Stack>
            <Divider />
            <Show when={!showContactForm}>
              <Stack gap={1.5} mt={2}>
                <Show when={accountType === 'Person'}>
                  <ContactInfoField
                    title={t('personal_data.personal_data_name')}
                    content={`${firstName1} ${lastName1}`}
                    additionalContent={`${firstName2} ${lastName2}`}
                  />
                </Show>
                <Show when={accountType === 'Organisation'}>
                  <ContactInfoField
                    title={t('personal_data.personal_data_company_name')}
                    content={customerName}
                    additionalContent={companyAddition}
                  />
                </Show>
                <ContactInfoField
                  title={t('personal_data.personal_data_telephone')}
                  content={phoneNumber}
                  emptyContentMessage={t('personal_data.not_specified')}
                />
                <ContactInfoField
                  title={t('personal_data.personal_data_email')}
                  content={email}
                  emptyContentMessage={t('personal_data.not_specified')}
                />
              </Stack>
            </Show>
            <Show when={showContactForm}>
              <PersonForm
                onChange={handleChange}
                onSubmit={handleSubmit}
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
                readOnlyName={firstName1}
                disabled={isSaveDisabled}
                isUpdatingAccountDetails={isUpdatingAccountDetails}
              />
            </Show>
          </Box>
          <Box mt={4}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography sx={{ my: 0 }} variant="leadEmphasized">
                {t('personal_data.personal_data_address')}
              </Typography>
              <Button sx={{ p: { xs: 0 } }} variant="text" endIcon={<NorthEastIcon />}>
                {t('personal_data.personal_data_report_relocation')}
              </Button>
            </Stack>
            <Divider />
            <Stack gap={1.5} mt={2}>
              <ContactInfoField title={t('personal_data.personal_data_street')} content={address} />
              <ContactInfoField title={t('personal_data.personal_data_postal_code')} content={postalCode} />
              <ContactInfoField title={t('personal_data.personal_data_city')} content={city} />
            </Stack>
          </Box>
        </Box>
      </Box>
      <DtpErrorModal
        errorTitle={t('http_errors.general_error_title')}
        errorDescription={t('http_errors.general_error_description_retry')}
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        isLoading={false}
        onConfirm={handleSubmit}
      />
    </>
  )
}

export default DtpPersonalDataFields
